@media print {
  nav {
    display: none;
  }

  .awsui-context-top-navigation {
    display: none;
  }

  .noPrint {
    display: none;
  }
}
